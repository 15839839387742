<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <hr class="mt-0">
      <ul class="nav">
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-basic'">
            <span class="menu-title">Home Page</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-home menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-basic">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/banner">Banner</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/counter">Counter</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/">Content</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-counter'">
            <span class="menu-title">About Us</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-email menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-counter">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/about">Content</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/counter">Counter</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-avant-garde'">
            <span class="menu-title">Avant Garde</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-city menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-avant-garde">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/avant-garde">Content</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/location">Location</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/avant-garde-banner">Slider</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-desgin-banner'">
            <span class="menu-title">Desgin</span>
            <i class="menu-arrow"></i>
            <i class="mdi  mdi-view-day menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-desgin-banner">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/desgin">Content</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/desgin-banner">Slider</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-amenities'">
            <span class="menu-title">Amenities</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-eye menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-amenities">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/amenities">Content</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/lifestyle">Lifestyle</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/amenities-banner">Slider</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-amenities2'">
            <span class="menu-title">Broker Registration</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-forum menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-amenities2">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/broker">Broker List</router-link>
              </li> 
            </ul>
          </b-collapse>
        </li> 
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-amenities1'">
            <span class="menu-title">Contact Us</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-forum menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-amenities1">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/contactData">Content</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">Contact Us</router-link>
              </li> 
            </ul>
          </b-collapse>
        </li> 
       
      </ul>
    </nav>
  </section>
</template>

<script>
export default {
  name: 'sidebar',
  data() {
    return {
      collapses: [
        { show: false },
        { show: false },
        { show: false }
      ]
    }
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted() {
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch: {
    $route() {
      document.querySelector('#sidebar').classList.toggle('active');
    }
  }
}
</script>